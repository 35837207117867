import React from "react"
import { Row, Col, Container } from "reactstrap"
import styled from "styled-components"
import { Link } from "react-router-dom"
import vars from "styles/variables"
import Image from "components/common/Image"
import MainNav from "components/ui/MainNav"

function Header() {
  return (
    <StyledHeader isNavOpen={true}>
      <Container>
        <Row className="align-items-center">
          <Col>
            <Link to="/">
              <Image
                src={require("assets/logos/main-hee-logo.svg")}
                alt="HEE"
                maxWidth={120}
              />
            </Link>
          </Col>
          <Col xs="auto">
            <MainNav />
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  padding: 1.5rem 0;
  background: ${props => (props.isNavOpen ? vars.white : "")};
  background: ${vars.blue};
  color: ${vars.white};
  margin-bottom: 2rem;
`
